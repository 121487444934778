


















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import { Admin } from '@/api'
import { generateCoupon } from '@/services/functions'

@Component({
  components: {
    Cleave,
    flatPickr
  }
})
export default class CreateCoupon extends Vue {
  couponName = ''
  rewardType = null
  validUntil: string | null = null
  oc = null
  whiteListAccountIds: string = ''

  success = false
  error = false
  message: string | null = ''

  rewardTypes = [
    { value: null, text: 'Select reward type'},
    { value: 'ECurrency', text: 'Olympus Coins' },
    { value: 'Items', text: 'Items' },
    { value: 'ECurrencyAndItems', text: 'Olympus Coins and Items' }
  ]

  items: CouponCreateItems[] = []

  @Watch('rewardType')
  onRewardTypeChange(newRewardType) {
    if (!newRewardType || this.couponWillHaveOC)
      this.items = []

    if (this.couponWillHaveItems && !this.items.length) {
      this.addItem()
    }
  }

  get couponWillHaveItems() {
    return ['Items', 'ECurrencyAndItems'].includes(this.rewardType!)
  }

  get couponWillHaveOC() {
    return ['ECurrency', 'ECurrencyAndItems'].includes(this.rewardType!)
  }

  isAllFieldsValid() {
    const couponHaveItems = this.couponWillHaveItems ? this.items.length > 0 : true
    const couponHaveOC = this.couponWillHaveOC ? this.oc! > 0 : true

    return this.couponName
        && this.rewardType
        && this.validUntil
        && couponHaveItems
        && couponHaveOC
  }

  addItem() {
    this.items.push({
      itemVNum: null,
      quantity: null
    })
  }

  deleteItem(i) {
    if (this.items.length > 1) {
      this.items.splice(i, 1)
    }
  }

  generate() {
    this.couponName = generateCoupon()
  }

  resetData() {
    this.couponName = ''
    this.rewardType = null
    this.validUntil = null
    this.oc = null
    this.whiteListAccountIds = ''

    this.success = false
    this.error = false
    this.message = ''
  }

  async validateCoupon() {
    const { couponName, items, oc, rewardType, validUntil } = this
    const couponInfo: CouponCreateForm = {
      validUntil,
      coupon: couponName,
      money: oc ?? 0,
      rewardType,
      rewards: items,
      whitelist: this.whiteListAccountIds.split('\n').filter(Boolean)
    }

    if (this.isAllFieldsValid()) {
      const data = await Admin.createCoupon(couponInfo)

      if (data.detail) {
        this.error = true
        this.message  = data.detail
      } else {
        this.success = true
        this.message = data
      }
    } else {
      this.error = true
      this.message = 'Coupon creation failed. Some fields are incorrect.'
    }
  }
}
